import logo from './logo.svg';
import './App.css';
//import download from '../public/download.jpeg.';
import Home from './Home';

function App() {
  return(
    <>
    <Home/>
    </>
  )
}

export default App;
